import { createRoot } from 'react-dom/client';
import { Alert, AlertColor, Snackbar, styled } from '@mui/material';
import { TOAST_TYPE } from './types';

const StyledAlert = styled(Alert)`
  font-size: 16px;
`;

const ToastMessage = (props: {
  isOpen: boolean;
  text: string;
  type: AlertColor;
}): JSX.Element => {
  const { isOpen, text, type } = props;
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={3500}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <StyledAlert severity={type}>{text}</StyledAlert>
    </Snackbar>
  );
};

export default ToastMessage;

const display = (type: AlertColor, text: string, duration: number): unknown => {
  const domNode = document.createElement('div');
  document.body.appendChild(domNode);

  const root = createRoot(domNode);
  const unmount = (): void => {
    root.unmount();
    domNode.remove();
  };
  root.render(<ToastMessage text={text} isOpen={true} type={type} />);
  if (duration) {
    setTimeout(unmount, duration);
  }
  return () => {
    unmount();
  };
};

ToastMessage.error = (text: string, duration = 3000) =>
  display(TOAST_TYPE.ERROR, text, duration);

ToastMessage.warning = (text: string, duration = 3000) =>
  display(TOAST_TYPE.WARNING, text, duration);

ToastMessage.info = (text: string, duration = 3000) =>
  display(TOAST_TYPE.INFO, text, duration);

ToastMessage.success = (text: string, duration = 3000) =>
  display(TOAST_TYPE.SUCCESS, text, duration);
