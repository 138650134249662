import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import updateEmailNotifications from './updateEmailNotifications';
import type { IEmailNotification } from '@/types/account';
import type { UpdateEmailNotificationsRequest } from './type';

const useUpdateEmailNotifications = (): UseMutationResult<
  Array<Partial<IEmailNotification>>,
  Error,
  UpdateEmailNotificationsRequest
> =>
  useMutation<
    Array<Partial<IEmailNotification>>,
    Error,
    UpdateEmailNotificationsRequest
  >({
    mutationFn: updateEmailNotifications,
  });

export default useUpdateEmailNotifications;
