import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { API_URL, DEBUG, ENVIRONMENT, SENTRY, VERSION } from '@/constants/env';

const isDebug = (): boolean => {
  if (DEBUG === 'true') {
    return true;
  }
  return false;
};
export const useSentry = (): string => {
  const isReady = typeof window !== 'undefined';
  useEffect(() => {
    Sentry.init({
      dsn: SENTRY,
      environment: ENVIRONMENT,
      integrations: [
        new Sentry.BrowserTracing({
          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost', API_URL],
        }),
        // Assuming you have the Replay integration available
      ],
      release: VERSION,
      debug: isDebug(),

      tracesSampleRate: 1.0,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
    });
  }, [isReady]);

  // Returning nothing, as Sentry does not provide a 'tear down' method
  return 'ok';
};
