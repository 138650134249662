import fetcher from '@/utils/fetcher';
import type { IEmailNotification } from '@/types/account';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const getEmailNotifications = (
  facilityId: string,
): Promise<IEmailNotification[]> =>
  fetcher<IEmailNotification[]>({
    endpoint: `/email-notifications/${facilityId}/list`,
  });

export default getEmailNotifications;
