import '@/index.css';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import App from '@components/App';

import { AuthenticationProvider } from '@lib/auth0/Provider';
import { DataFetchingProvider } from '@lib/react-query/Provider';
import { LinkProvider } from './pages/PublicPayment/PayNow/PlaidLink/Link';
import { UserAuthProvider } from './pages/PublicPayment/PayNow/PlaidLink/UserAuth';
import { analyticsLoad } from '@/utils/analytics';

import { theme } from './theme';

dayjs.extend(utc);

const rootElement = document.querySelector<HTMLDivElement>('#root');

if (rootElement == null) throw new Error('`root` element was not found.');

analyticsLoad();

createRoot(rootElement).render(
  <UserAuthProvider>
    <LinkProvider>
      <DataFetchingProvider>
        <AuthenticationProvider>
          <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ThemeProvider<typeof theme> theme={theme}>
                <CssBaseline />
                <App />
              </ThemeProvider>
            </LocalizationProvider>
          </BrowserRouter>
        </AuthenticationProvider>
      </DataFetchingProvider>
    </LinkProvider>
  </UserAuthProvider>,
);
