import { useState, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import type { SelectChangeEvent } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';

import InputDropdown from '@/components/Forms/InputDropdown';
import type { OptionValue } from '@/components/Forms/InputDropdown/types';
import type { SystemUserAuthResponse } from '@/types/user';
import { ApplicationStatusEnum } from '@/api/hooks/useGetBusinessValidation/type';
import { EEmailNotificationType } from '@/types/account';
import useUpdateEmailNotifications from '@/api/hooks/useUpdateEmailNotifications';
import useGetEmailNotifications from '@/api/hooks/useGetEmailNotifications';
import ToastMessage from '@/components/UI/ToastMessage';
import { useRoles } from '@/store/useRoles';

interface IModalEmailNotifications {
  open: boolean;
  systemUser?: SystemUserAuthResponse;
  onClose: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: '24px',
  outline: 'none',
};

const SUBSCRIBE_ON = 'SUBSCRIBE_ON';

const EmailNotificationType = {
  CASH_DEPOSIT: 'Cash Deposit',
  CHANGE_ORDER: 'Change Order',
  CREDIT_TRANSACTION: 'Credit Transaction',
  DEBIT_TRANSACTION: 'Debit Transaction',
  SCHEDULE_PAYMENT: 'Schedule Payment',
  SUBSCRIBE_ON: 'Subscribe InfoAlerts',
};

const checkedDefault = {
  [EEmailNotificationType.CASH_DEPOSIT]: true,
  [EEmailNotificationType.CHANGE_ORDER]: true,
  [EEmailNotificationType.CREDIT_TRANSACTION]: true,
  [EEmailNotificationType.DEBIT_TRANSACTION]: true,
  [EEmailNotificationType.SCHEDULE_PAYMENT]: true,
  [SUBSCRIBE_ON]: true,
};

export default function ModalEmailNotifications({
  open,
  systemUser,
  onClose,
}: IModalEmailNotifications) {
  const [isSaving, setIsSaving] = useState(false);
  const [facility, setFacility] = useState<string>('');
  const [checked, setChecked] = useState({ ...checkedDefault });
  const { isOperating } = useRoles((role) => ({
    isOperating: role.isOperating,
    isCardholder: role.isCardholder,
  }));

  const optionList = isOperating
    ? [SUBSCRIBE_ON, ...Object.keys(EEmailNotificationType)]
    : [EEmailNotificationType.DEBIT_TRANSACTION];

  const { mutate: updateEmailNotifications } = useUpdateEmailNotifications();

  const {
    data: emailNotifications,
    refetch,
    isPending,
  } = useGetEmailNotifications(facility, {
    enabled: !!facility,
  });

  const handleToggle = (key: string) => () => {
    const isClickUnsubscribeAll = key === SUBSCRIBE_ON;

    if (isClickUnsubscribeAll) {
      if (checked[SUBSCRIBE_ON]) {
        setChecked({
          [EEmailNotificationType.CASH_DEPOSIT]: false,
          [EEmailNotificationType.CHANGE_ORDER]: false,
          [EEmailNotificationType.CREDIT_TRANSACTION]: false,
          [EEmailNotificationType.DEBIT_TRANSACTION]: false,
          [EEmailNotificationType.SCHEDULE_PAYMENT]: false,
          [SUBSCRIBE_ON]: false,
        });
      } else {
        setChecked({ ...checked, [SUBSCRIBE_ON]: true });
      }

      return;
    }

    const newChecked = {
      ...checked,
      [key]: !checked[key],
      [SUBSCRIBE_ON]: true,
    };

    setChecked({
      ...newChecked,
    });
  };

  const listFacilities = useMemo(() => {
    return (
      systemUser?.facilities
        ?.filter(
          (facility) =>
            facility.applicationStatus === ApplicationStatusEnum.APPROVED,
        )
        ?.map((facility) => ({
          label: facility.facilityName,
          id: facility.id,
          value: facility.id,
        })) || []
    );
  }, [systemUser?.facilities]);

  const onSetFacility = (
    facility: SelectChangeEvent<OptionValue<string>>,
  ): void => {
    setFacility(facility?.target?.value as string);
  };

  const onSave = () => {
    const payload = Object.keys(checked)
      .filter((key) => key !== SUBSCRIBE_ON)
      .map((key) => ({
        facilityId: facility,
        type: key,
        enabled: checked[key],
        id: emailNotifications?.find(
          (item) => item.type === key && item.facilityId === facility,
        )?.id,
      }));

    setIsSaving(true);

    updateEmailNotifications(payload, {
      onSuccess: async () => {
        setIsSaving(false);
        ToastMessage.success('Email notifications saved successfully');
        await refetch();
        onClose();
      },
      onError: () => {
        setIsSaving(false);
        ToastMessage.error('Failed to save email notifications');
      },
    });
  };

  useEffect(() => {
    if (listFacilities.length && !facility) {
      setFacility(listFacilities?.[0]?.id as string);
    }
  }, [listFacilities, facility]);

  useEffect(() => {
    if (emailNotifications?.length) {
      const isHasEnabled = emailNotifications.some((item) => item.enabled);

      setChecked((prev) => {
        const newChecked = { ...prev, [SUBSCRIBE_ON]: isHasEnabled };

        emailNotifications.forEach((item) => {
          newChecked[item.type] = item.enabled;
        });

        return newChecked;
      });
    } else {
      setChecked({ ...checkedDefault });
    }
  }, [emailNotifications]);

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Setting Email Notifications
          </Typography>

          <Box sx={{ mt: 2, display: 'flex', gap: 1, flexDirection: 'column' }}>
            <InputDropdown
              width="24rem"
              name={'transactionType'}
              value={facility}
              options={listFacilities}
              placeholder="Select"
              onChange={onSetFacility}
              noNoneOption
            />

            <List>
              {optionList.map((value) => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                  <ListItem key={value} disablePadding>
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(value)}
                      dense
                      disabled={
                        isPending ||
                        (value !== SUBSCRIBE_ON && !checked[SUBSCRIBE_ON])
                      }
                    >
                      <ListItemText
                        id={labelId}
                        primary={EmailNotificationType[value]}
                      />
                      <ListItemIcon
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          height: '38px',
                        }}
                      >
                        {isPending ? (
                          <CircularProgress
                            color="inherit"
                            size={16}
                            sx={{ mr: '12px' }}
                          />
                        ) : (
                          <Switch checked={checked[value]} disableRipple />
                        )}
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              mt: 1,
            }}
          >
            <Button
              onClick={onSave}
              disabled={isSaving}
              variant="contained"
              sx={{
                minWidth: '110px',
              }}
            >
              {isSaving && (
                <CircularProgress
                  color="inherit"
                  size={14}
                  sx={{ mr: '2px' }}
                />
              )}
              <span>Save</span>
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
