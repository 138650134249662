import { useQuery, UseQueryResult } from '@tanstack/react-query';
import getEmailNotifications from './getEmailNotifications';
import type { QueryOptionsRequest } from '@/api/types';
import type { IEmailNotification } from '@/types/account';

const useGetEmailNotifications = (
  facilityId: string,
  queryOptions?: QueryOptionsRequest<IEmailNotification[]>,
): UseQueryResult<IEmailNotification[], Error> => {
  return useQuery<IEmailNotification[], Error>({
    queryKey: ['getEmailNotifications', facilityId],
    queryFn: async () => await getEmailNotifications(facilityId),
    ...queryOptions,
  });
};

export default useGetEmailNotifications;
