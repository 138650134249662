import { Router } from '@/constants/router';

import {
  NavigationPortalItem,
  NavPortalItemsEnum,
} from '@/features/ui/navigation';
import {
  PAYMENT_ENROLLMENT_APPROVED_BUSINESSES,
  PAYMENT_ENROLLMENT_ENABLED,
} from '@/constants/env';
import { SystemRoleType } from '@pages/BankAccount/SystemUsers/__utils__/mock';
import type { SystemUserAuthResponse } from '@/types/user';

const bankAccountNavItems: NavigationPortalItem[] = [
  {
    id: NavPortalItemsEnum.DASHBOARD,
    path: Router.BankAccount.Dashboard,
    text: 'Dashboard',
  },
  {
    id: NavPortalItemsEnum.ACCOUNT_OVERVIEW,
    path: Router.BankAccount.Overview,
    text: 'Account Overview',
  },
  {
    id: NavPortalItemsEnum.PAYMENTS_AND_PAYEES,
    path: Router.BankAccount.PaymentsAndPayees,
    text: 'Payments & Payees',
  },
  {
    id: NavPortalItemsEnum.AUTHORIZED_CARDHOLDERS,
    text: 'Authorized Cardholders',
    children: [
      {
        id: NavPortalItemsEnum.AUTHORIZED_CARDHOLDERS_DEBIT_LIMITS,
        path: Router.BankAccount.DebitLimits,
        text: 'Debit Limits',
      },
      {
        id: NavPortalItemsEnum.AUTHORIZED_CARDHOLDERS_CARDHOLDERS,
        path: Router.BankAccount.Cardholders,
        text: 'Cardholders',
      },
      // {
      //   id: NavPortalItemsEnum.MANAGE_TAGS,
      //   path: Router.BankAccount.ManageTags,
      //   text: 'Tags',
      // },
    ],
  },
  {
    id: NavPortalItemsEnum.BUSINESS_DETAILS,
    path: Router.BankAccount.BusinessDetails,
    text: 'Business Details',
  },
  {
    id: NavPortalItemsEnum.LOCATION_DETAILS,
    path: Router.BankAccount.LocationDetails,
    text: 'Location Details',
  },
  {
    id: NavPortalItemsEnum.SYSTEM_USERS,
    text: 'System Users',
    children: [
      {
        id: NavPortalItemsEnum.SYSTEM_USERS_ROLE,
        path: Router.BankAccount.SystemRoleType,
        text: 'Roles',
      },
      {
        id: NavPortalItemsEnum.SYSTEM_USERS_ITEM,
        path: Router.BankAccount.SystemUsers,
        text: 'Users',
      },
    ],
  },
  {
    id: NavPortalItemsEnum.PAYMENT_SYSTEM_ENROLLMENT,
    path: Router.BankAccount.PaymentSystemEnrollment,
    text: 'Payment Processing',
    isActive: PAYMENT_ENROLLMENT_ENABLED,
  },
  // {
  //   id: NavPortalItemsEnum.MANAGE,
  //   text: 'Manage',
  //   children: [
  //     {
  //       id: NavPortalItemsEnum.MANAGE_TAGS,
  //       path: Router.BankAccount.ManageTags,
  //       text: 'Tags',
  //     },
  //   ],
  // },
];

const cardholderNavItems: NavigationPortalItem[] = [
  {
    id: NavPortalItemsEnum.AUTHORIZED_CARDHOLDER_DETAIL,
    path: Router.CardholderRole.Detail,
    text: 'Authorized Cardholders',
  },
];

function isPaymentEnrollment(navItemId: string) {
  return navItemId === NavPortalItemsEnum.PAYMENT_SYSTEM_ENROLLMENT;
}

const enablePaymentEnrollment = (
  systemUser: SystemUserAuthResponse | undefined,
) => {
  if (!systemUser) {
    return false;
  }
  return (
    systemUser?.businessId &&
    PAYMENT_ENROLLMENT_APPROVED_BUSINESSES.includes(systemUser?.businessId)
  );
};

export const navItems = (
  userRole: string[],
  systemUser: SystemUserAuthResponse | undefined,
): NavigationPortalItem[] => {
  const activeNavItems = bankAccountNavItems.filter((navItem) => {
    if (
      isPaymentEnrollment(navItem.id) &&
      enablePaymentEnrollment(systemUser)
    ) {
      navItem.isActive = true;
    }
    return navItem.isActive !== false;
  });

  if (userRole.includes(SystemRoleType.ADMINISTRATOR)) {
    return activeNavItems;
  }

  const enableShowDashboard = (id: NavPortalItemsEnum) =>
    id === NavPortalItemsEnum.DASHBOARD &&
    systemUser &&
    systemUser?.facilities?.length > 1;

  if (userRole.includes(SystemRoleType.OPERATING)) {
    return activeNavItems.filter(
      (navItem) =>
        [
          NavPortalItemsEnum.ACCOUNT_OVERVIEW,
          NavPortalItemsEnum.PAYMENTS_AND_PAYEES,
          NavPortalItemsEnum.AUTHORIZED_CARDHOLDERS,
        ].includes(navItem.id) || enableShowDashboard(navItem.id),
    );
  }

  if (userRole.includes(SystemRoleType.OPERATING)) {
    return activeNavItems.filter(
      (navItem) =>
        [
          NavPortalItemsEnum.ACCOUNT_OVERVIEW,
          NavPortalItemsEnum.PAYMENTS_AND_PAYEES,
          NavPortalItemsEnum.AUTHORIZED_CARDHOLDERS,
        ].includes(navItem.id) || enableShowDashboard(navItem.id),
    );
  }

  const hasCardholderRole = userRole.includes(SystemRoleType.CARDHOLDER);

  if (userRole.includes(SystemRoleType.ACCOUNTING)) {
    // Role: Accounting
    return activeNavItems
      .filter(
        (navItem) =>
          [NavPortalItemsEnum.ACCOUNT_OVERVIEW].includes(navItem.id) ||
          enableShowDashboard(navItem.id),
      )
      .concat(hasCardholderRole ? cardholderNavItems : []);
  }

  if (hasCardholderRole) {
    return cardholderNavItems;
  }

  return [];
};
