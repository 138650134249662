import { isEmpty } from 'lodash';
import { FORMAT_FROM_SYNCTERA_CURRENCY_VALUE } from '@/constants/misc';

export function formatMoney(number: number): string {
  return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export function formatMoneyDisplay(number: number): string {
  const convertedNumber = FORMAT_FROM_SYNCTERA_CURRENCY_VALUE(number);

  return convertedNumber.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
}

export function capitalize(s: string): string {
  if (s === undefined || s[0] === undefined) return '';
  return `${s[0]?.toUpperCase()}${s.slice(1).toLowerCase()}`;
}

export function patternMatch({
  input,
  template,
}: {
  input: string;
  template: string;
}): string {
  try {
    let j = 0;
    let plaintext = '';
    let countj = 0;
    while (j < template.length) {
      if (countj > input.length - 1) {
        template = template.substring(0, j);
        break;
      }

      // eslint-disable-next-line eqeqeq
      if (template[j] == input[j]) {
        j++;
        countj++;
        continue;
      }

      // eslint-disable-next-line eqeqeq
      if (template[j] == 'x') {
        template =
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          template.substring(0, j) + input[countj] + template.substring(j + 1);
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        plaintext = plaintext + input[countj];
        countj++;
      }
      j++;
    }

    return template;
  } catch {
    return '';
  }
}

export const maskFirstN = (n: number, str: string): string => {
  let finalString = '';

  for (let i = 0; i < n; i++) {
    finalString += '*';
  }
  finalString += str.slice(n);
  return finalString;
};

export const maskButNotTheLastN = (n: number, str: string): string => {
  if (!str) return '';

  let finalString = '';

  for (let i = 0; i < n; i++) {
    finalString += '*';
  }
  finalString += str.slice(-n);
  return finalString;
};

export const convertAmountToSyncterraBalance = (value: string): number => {
  const strNumber = value.replace(/,+/g, '');
  return parseFloat(strNumber) * 100;
};

export const convertSyncterraBalanceToAmount = (
  value: string | number,
): string => {
  const convertedAmount = Number(value) / 100;
  const amount = convertedAmount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return amount;
};

export const formatMultiQueryParams = (query: string, page: string): string => {
  if (isEmpty(page)) return query;
  if (isEmpty(query)) return `?pageToken=${page}`;
  return query.concat(`&pageToken=${page}`);
};

export const isPhoneValid = (value: string): boolean => {
  const myPhoneRegex =
    /^(\+)(\(\d{3}\) \d{3}-\d{4})|(\d\(\d{3}\) \d{3}-\d{4})|(\+)\d{10,11}$/;
  return myPhoneRegex.test(value);
};

export const numberWithCommas = (x: number): string => {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

export const formatUSPhoneNumber = (phoneNumber: string): string => {
  let phoneStr = phoneNumber;

  phoneStr = phoneStr.replace(/\D/g, '');

  if (phoneStr.length === 11 && phoneStr.startsWith('1')) {
    phoneStr = phoneStr.substring(1);
  }

  return phoneStr;
};

export const isEmailValid = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
