import type { SyncteraPerson } from '@/types/syncteraPerson';
import type { Tag } from '@/types/tag';
export interface BusinessAccountDetails {
  id: string;
  businessIds: string[];
  isFunded: boolean;
  accountNumber: string;
  accountNumberMasked: string;
  bankRouting: string;
  availableBalance: number;
  businessName: string | null;
  accountBalance?: number;

  facilityName?: string;
  facilityId?: string;
  syncteraCardAccountId?: string;
}

export interface CardholderDetails {
  id: string;
  name: string;
  currency: string;
  monthlyLimit: number | 'N/A';
  cardStatus: string;
  cardBrand: string;
  cardNumber: string;
  cardType: string;
  expiredAt: string;
  spendControlIds?: string[];
  facilityName: string;
  facilityId: string;
  isMyCard?: boolean;
  manage: {
    disabled: boolean;
    action: () => void;
  };
  businessId?: string;
  tags?: Tag[];
}

export enum CardBrands {
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
}

export interface CardOwnerInformation {
  name: string;
  cardBrand: CardBrands;
}

export interface CardHolderTableConfig extends Omit<CardholderDetails, 'name'> {
  cardOwnerInfo: CardOwnerInformation;
}

export interface CardHolderInformation {
  cardholderId: string;
  person: SyncteraPerson;
  card: CardInformation;
  tags: Tag[];
}

export interface WidgetInformation {
  facilityId: string;
  cardId: string;
  type: string;
}

export interface WidgetResponse {
  url: string;
}

export interface CardUserInformation {
  firstName: string;
  lastName: string;
  id: string;
}

export interface CardInformation {
  cardType: string;
  lastFour: string;
  cardBrand: string;
  cardStatus: string;
  issuedAt: Date;
  expiredAt: Date;
  monthlyLimit: number;
  embossName: string;
  spendControlIds: string[];
  postedTransactions: Transaction[];
  pendingTransactions: Transaction[];
  amount: number;
  syncteraAccountId: string;
  currentUtilization: number;
  isMyCard?: boolean;
  nickname?: string;
}

export enum TransactionTypeEnum {
  credit = 'credit',
  debit = 'debit',
}

interface Receipt {
  id: string;
  url: string;
  fileName: string;
}

export interface Transaction {
  id: number;
  timeDate: string;
  name: string;
  type: string;
  subtype?: string;
  amount: number;
  balanceAfterTransaction: number;
  transactionType?: TransactionTypeEnum;
  description: string;
  children?: Transaction[];
  isAchBtnTx: boolean;
  reason?: string;
  uuid: string;
  check?: {
    frontImageId: string;
    backImageId: string;
  };
  receipts?: Receipt[];
}

export interface TransactionResponse {
  result: Transaction[];
  next_page_token: string | null;
}

export interface PlaidLinkTokenResponse {
  business_id: string;
  client_name: string;
  country_codes: string[];
  expiration: string;
  language: string;
  link_token: string;
  request_id: string;
  type: string;
  verify_owner: boolean;
}

export interface IAccountDetail {
  accountId: string;
  institutionId: string;
  lastFour: string;
}

export interface IAddAccount {
  public_token: string;
  accounts: string[];
  institution: string;
  userToken: string;
  accountDetails: IAccountDetail[];
}

export interface IList {
  id: string;
  nickname: string;
  account_identifiers: {
    number: string;
  };
  vendor_data: {
    account_number_mask: string;
    institution_id: string;
  };
  link_status: string;
}

export interface IPaymentDetails {
  city: string;
  merchantId: string;
  orderId: string;
  postalCode: string;
  state: string;
  street: string;
  transactionAmount: string;
  unit: string;
  externalMerchantId: string;
  merchantName: string;
  createdAt?: string;
  lifeTime?: number;
}

export enum BuyerTransactionStatusEnum {
  INITIATED = 'INITIATED',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

export interface IBuyerTransaction {
  id: string;
  buyerId: string;
  orderId: string;
  achId: string;
  syncteraTransactionId: string | null;
  internalTransactionId: string | null;
  status: BuyerTransactionStatusEnum;
  createdAt: Date;
  updatedAt: Date;
  externalMerchantId: string | null;
  ecommercePlatformId: string | null;
  syncteraExternalAccountId: string | null;
}

export enum EEmailNotificationType {
  CASH_DEPOSIT = 'CASH_DEPOSIT',
  CHANGE_ORDER = 'CHANGE_ORDER',
  CREDIT_TRANSACTION = 'CREDIT_TRANSACTION',
  DEBIT_TRANSACTION = 'DEBIT_TRANSACTION',
  SCHEDULE_PAYMENT = 'SCHEDULE_PAYMENT',
}

export interface IEmailNotification {
  id: string;
  userId: string;
  facilityId: string;
  type: EEmailNotificationType;
  enabled: boolean;
}
