import fetcher from '@/utils/fetcher';

import type { IEmailNotification } from '@/types/account';
import type { UpdateEmailNotificationsRequest } from './type';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const createTag = (
  payload: UpdateEmailNotificationsRequest,
): Promise<IEmailNotification[]> =>
  fetcher<IEmailNotification[]>({
    body: JSON.stringify(payload),
    endpoint: `/email-notifications/${payload?.[0]?.facilityId as string}/update`,
    method: 'POST',
  });

export default createTag;
